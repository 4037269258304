<template>
  <div>
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection />
      <ElectoralSearchIndex />
      <!-- <TeamSection /> -->
      <!--<ContactSection /> -->
      <!-- <CtaSection /> -->
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro/>
      <ModalMenuSection />
      <InstruccionesPopUpModal />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../../Scrollup/Scrollup'
import HeaderSection from '../../Header/HeaderOne'
import BreadcrumbSection from '../../Breadcrumb/ElectoralSearch'
import ElectoralSearchIndex from '../../ElectoralSearchItems/ElectoralSearchIndex'
/* import TeamSection from '../../Team/TeamOne' */
/* import ContactSection from '../../Contact/ContactOne' */
// import CtaSection from '../../Cta/Cta'
import FooterSection from '../../Footer/FooterOne'
import ModalSearchSection from '../../Modal/ModalSearch/ModalSearch'
import ModalRegistro from '../../Modal/ModalSearch/ModalRegistro';
import ModalMenuSection from '../../Modal/ModalMenu/ModalMenu'
import InstruccionesPopUpModal from "../../Modal/ModalAds/InstruccionesPopUpModal"

export default {
  name: 'Search',
  metaInfo: {
    title:"Electoral Tech & Legal | Electoral-Search",
    description:"",
  },
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    ElectoralSearchIndex,
    InstruccionesPopUpModal,
    //TeamSection,
    //ContactSection,
    //CtaSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro
  },
}
</script>

<style>

</style>