<template>
    <div 
        class="modal fade"
        id="InstruccionesPopUp"
        tabindex="-1"
        role="dialog"
        aria-labelledby="InstruccionesPopUp"
        aria-hidden="true"
        background="blue"
    >
    <!--
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo">DISTRIBUCION DE REGIDORES POR EL PRNCIPIO DE REPRESETACION PROPORCIONAL, LISTA DE AYUNTAMIENTOS:</h5>
          </div>
            <div class="container-fluid d-flex justify-content-center mx-0">
                <div class="container">
                    <button type="button" class="list-group-item list-group-item-action active pl-3" disabled>
                        Lista De Ayuntamientos En Veracruz
                    </button>
                    <div v-for="n in 212" :key="n">
                        <button type="button" class="list-group-item list-group-item-action">{{n }} - Nombre Del Ayuntamiento </button>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content modal-settings">
                <img src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/DocumentsAndFiles%2FInstructivoESearch%20(2).png?alt=media&token=ef7221cb-cc3c-4916-a141-e33135504e8a" alt="">
                <form class="popUp pt-3">
                    <div class="form-group">
                        <div class="d-flex justify-content-center">
                            <div></div>
                            <div class="col-2"> 
                                <button class="btn" type="button" @click="closeInstrucciones()">
                                    Vale! 
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {fr} from "../../../firebase";

export default {
    name: "InstruccionesPopUpModal",
    data (){
        return{
            correoSus: "",
            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd; 
        },
    },

    methods: {

        closeInstrucciones(){
            window.$("#InstruccionesPopUp").modal("hide")
        },

        async saveCorreos(){
            const today = Date.now();
            const data = {
                correo: this.correoSus,
                fechaRegistro: today,
            }
            await fr
            .collection("correosBD")
            .doc()
            .set({data})
            window.$("#EmailPopUp").modal("hide")
        }
        
    },
    
}
</script>

<style scoped>
.modal-settings{
    background-color: #755ea7!important;
}
.form-settings{
    box-shadow: 1px 5px 5px #6932a8!important;
}
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}
.container{
    height:450px;
    overflow-y: auto;
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

