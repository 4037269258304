<template>
  <div>
    <template>
      <div>
        <ScrollupSection />
        <div class="main overflow-hidden">
          <HeaderSection />
          <PerfilBreadcrumb :titulo="titulo" :descripcion="descripcion" />
          <section id="service" class="section service-area mt-5 px-5">
            <p>
              En cumplimiento con lo establecido en el artículo 68 de la <b>Ley General de Transparencia y Acceso a la Información 
              Pública</b> y el artículo 6 y demás relativos a la protección de los datos personales descritos en la <b>Ley General de 
              Protección de Datos Personales en Posesión de Sujetos Obligados</b>, le informamos nuestra política de privacidad y 
              manejo de datos personales y hacemos el siguiente compromiso:
            </p>
            <br>
            <br>

            <p class="px-5">
              1.- Los datos que le solicitamos en el formulario de contacto únicamente serán utilizados para poder establecer contacto 
              con usted en relación a la petición o requerimiento que haya realizado a esta empresa.

            </p>

            <br>
            <br>

            <p class="px-5">
              2.- Los datos que ingrese usted en el formulario de contacto no serán difundidos, distribuidos o comercializados y 
              serán protegidos de acuerdo a la normatividad vigente.
            </p>

            <br>
            <br>

            <p class="px-5">
              3.- En caso de que desee que sus datos sean eliminados de nuestra base de datos, en cualquier momento podrá usted solicitar 
              la baja de los mismos mediante un correo electrónico a <a href="mailto:lawtechelectoral@gmail.com">coordinacion@electoralatech.com.mx</a> o por correo postal a la dirección: 
              <b>Carretera Cuota Veracruz-Xalapa, Km 10. Lote 6. A 200 mts de puente peatonal, amarillo y entrada a la Col. La Tinaja, El Lencero C.P. 91640, 
              Emiliano Zapata, Veracruz. México.</b>
            </p>

            <br>
            <br>

            <p class="px-5">
              4.- Su petición podrá ser incluida dentro de los informes estadísticos que se elaboren para el seguimiento de avances institucionales. 
              No obstante, dichos informes serán únicamente estadísticos y no incluirán información que permitan identificar su identidad.
            </p>

            <br>
            <br>

            <p class="px-5">
              5.- Sus datos personales únicamente podrán ser proporcionados a terceros de acuerdo con lo estrictamente señalado en el art. 22 fracción III de la 
              Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados.
            </p>

            <br>
            <br>

            <p class="px-5">
              6.- Si necesita usted mayor información por favor escríbanos a <a href="mailto:lawtechelectoral@gmail.com">coordinacion@electoralatech.com.mx</a> o comuníquese al teléfono (+52) 2283078010  
              o bien, por correo postal a la dirección: 
              <b>Carretera Cuota Veracruz-Xalapa, Km 10. Lote 6. A 200 mts de puente peatonal amarillo y entrada a la Col. La Tinaja, El Lencero C.P. 91640, 
              Emiliano Zapata, Veracruz. México.</b>
            </p>

            <br>
            <br>
            <br>

            
            

            


            
          </section>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import PerfilBreadcrumb from "../components/Breadcrumb/PerfilBreadcrumb";


export default {
  name: "DatosPersonales",
  components: {
    ScrollupSection,
    HeaderSection,
    PerfilBreadcrumb
  },
  data () {
    return {
      titulo: 'Política de manejo de datos personales',
      descripcion: ''
    }
  }
}
</script>

<style scoped>

p{
  font-size: 20px;
  font-weight: 300;
}

li{
  font-size: 20px;
  font-weight: 300;
}

</style>