<template> 
  <div class="container">
    <div class="filtros  my-5">
      <div class="text-center">
        <h2 class="mb-2 text">Selecciona el estado</h2>
      </div> 
      <div class="d-flex justify-content-center" >
        <div class="encabezados">
          <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">

            <li class="nav-item" role="presentation">
              <a class="nav-link " id="pills-all-states" data-toggle="pill" href="#pills-all-states" role="tab"
                  aria-controls="pills-all-states" aria-selected="true" @click="SelectState(1)">
                Todos
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-veracruz" data-toggle="pill" href="#pills-veracruz" role="tab"
                  aria-controls="pills-veracruz" aria-selected="false" @click="SelectState(2)">
                Veracruz
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div v-if="StateBand==1">
      <div class="filtros  my-5">
        <div class="text-center">
          <h2 class="mb-2 text">Selecciona el Órgano</h2>
        </div> 
        <div class="d-flex justify-content-center" >
          <div class="encabezados"> 
            <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">

              <li class="nav-item" role="presentation">
                <a class="nav-link " id="pills-all-organs" data-toggle="pill" href="#pills-all-organs" role="tab"
                    aria-controls="pills-all-organs" aria-selected="true" @click="SelectOrgano(1)">
                  Todos
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-tev" data-toggle="pill" href="#pills-tev" role="tab"
                    aria-controls="pills-tev" aria-selected="false" @click="SelectOrgano(2)">
                  TEV
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-sx" data-toggle="pill" href="#pills-sx" role="tab"
                    aria-controls="pills-sx" aria-selected="false" @click="SelectOrgano(3)">
                  SX
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container h-100 mt-5" v-if="OrganoBand==1">

      <div class="filtros  my-5">
        <div class="text-center">
          <h2 class="mb-2 text">Selecciona El Tipo De Documento</h2>
        </div> 
        <div class="d-flex justify-content-center" >
          <div class="encabezados">
            <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-acuerdos" data-toggle="pill" href="#pills-acuerdos" role="tab"
                    aria-controls="pills-acuerdos" aria-selected="false" @click="SelectType(2)">
                  Acuerdos
                </a>
              </li>

              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-solo-sentencia" data-toggle="pill" href="#pills-solo-sentencia" role="tab"
                    aria-controls="pills-solo-sentencia" aria-selected="false" @click="SelectType(4)">
                  Sentencias
                </a>
              </li>

              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-sentencias" data-toggle="pill" href="#pills-sentencias" role="tab"
                    aria-controls="pills-sentencias" aria-selected="false" @click="SelectType(3)">
                  Expediente
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>


      <div v-if="TypeBand==1">
        <div class="d-flex justify-content-center">
          <div class="chips__choice">
              <div class="chip" style="background-color: white;" aria-disabled=""> <h5>FILTRAR RESULTADOS: </h5> </div>
              <div id="filter0" class="chip chip--active" @click="SelectFilter(0)">Num. Expediente</div>
              <div v-if="TypeNumber==2" id="filter1" class="chip" @click="SelectFilter(1)">Fecha</div>
              <div v-if="AcuerdoBand==1" id="filter2" class="chip" @click="SelectFilter(2)">Hoy</div>
          </div>
        </div>
        
        <div class="d-flex justify-content-center h-100">
          <div class="searchbar">
            <input id="busquedaInputId" v-on:keyup.enter="BuscarContenido(busquedaText.toUpperCase())" v-model="busquedaText" class="search_input ml-1" type="text" >
            <a  @click="BuscarContenido(busquedaText.toUpperCase())" class="search_icon"><i class="fas fa-search"></i></a>
            <p class="mt-3 px-4">
               <i>{{hintText}}</i>
            </p>
          </div>
        </div>
      </div>

      <div v-if="resultadosBand==1">
        <div class="container-fluid d-flex justify-content-center mx-0">
            <div class="container resultados-box">
                <button style="background-color: black" type="text" class="list-group-item list-group-item-action active pl-3" disabled>
                   <b class="resultados-text">RESULTADOS DE LA BÚSQUEDA:</b>  
                </button>
                <button style="background-color: #4DA1A9;" type="text" class="list-group-item list-group-item-action active pl-3" disabled>
                   <b class="ml-1" v-if="resultadosBusqueda.length > 0">SE ENCONTRARON ESTOS DOCUMENTOS:</b>
                   <b class="ml-1" v-if="resultadosBusqueda.length == 0"> NO SE ENCONTRARON RESULTADOS </b>
                </button>
                <div v-for="i in (resultadosCantidad)" :key="i">
                    <button type="button" class="list-group-item list-group-item-action" @click="AbrirArchivo(i-1)"> {{resultadosBusqueda[(i-1)].Doc_Name}} </button>
                </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { fr,analytics} from "../../firebase";


/*
  ESTADOS: 1: Todos, 2: Veracruz ....
  TIPO DOCUMENTO: 1: Todos , 2: Acuerdos , 3: Sentencias 
  FILTRAR RESULTADOS: 1: Fecha , 2: Num. Expediente , 3: Nombre

*/
export default {
  name: "ElectoralSearchIndex",
  data(){
    return {
      SelectedState: 0,
      SelectedType: 0,
      SelectedOrgano: 0,
      SelectedFilter: 0,
      StateBand: 0,
      OrganoBand: 0,
      TypeBand: 0,
      AcuerdoBand: 0,
      resultadosBand: 0,
      TypeNumber: 0,
       busquedaHint: "Seleccionar un filtro hara tu busqueda más rapida",

      busquedaText: "",

      estado: "",
      organo: "",
      documento: "", 

      filtro: 0,
      hintText: "",

      resultadosBusqueda: [],
      resultadosCantidad: 0,

      sentenciaBand: false,

    }
  },

  mounted() {
    this.InstruccionesPopUpModal();
  },

  methods: {
/*
    InstruccionesPopUpModal(){
        const validation = window.sessionStorage.getItem("IntruPopUp");
        if(validation == null){
          console.log("ENTRE AL POPUP")
          setTimeout(() => { this.ShowModal()}, 2000);
        }
        window.sessionStorage.setItem("IntruPopUp",true);       
    },

    ShowModal(){
      window.$("#InstruccionesPopUp").modal("show")
    },*/
    SelectState(n){
      this.SelectedState = n;
      this.StateBand = 1;
      console.log("El Estado: "+ this.SelectedState)
      switch(this.SelectedState){
        case 1: 
          this.estado = null;
          break;
        case 2: 
          this.estado = "veracruz";
          break;
        case 3: 
          this.estado = "otro";
          break;
        default: 
          this.estado = null;
          break;
      }
    },

    SelectOrgano(n){
      this.SelectedOrgano = n;
      this.OrganoBand = 1;
      console.log("El Organo: "+ this.SelectedOrgano);
      switch(this.SelectedOrgano){
        case 1: 
          this.organo = null;
          break;
        case 2: 
          this.organo = "tev";
          this.busquedaText = "TEV-"
          this.hintText = "EJEMPLO: TEV-JDC-59/2021";
          window.$('.chips__choice .chip').removeClass('chip--active');
          window.$('#filter0').addClass("chip--active");
          break;
        case 3: 
          this.organo = "sx";
          this.busquedaText = "SX-";
          this.hintText = "EJEMPLO: SX-JDC-59/2021";
          window.$('.chips__choice .chip').removeClass('chip--active');
          window.$('#filter0').addClass("chip--active");
          break;
        default:
          this.organo = null;
          break;

      }
    },

    SelectType(n){
      this.SelectedType = n;
      this.TypeBand = 1;
      console.log("El Tipo: "+ this.SelectedType);
      switch(this.SelectedType){
        case 2: 
          this.documento = "acuerdo";
          this.TypeNumber = 2;
          this.AcuerdoBand = 1;
          this.sentenciaBand = false;
          break;
        case 3: 
          this.documento = "expediente";
          this.TypeNumber = 3;
          this.AcuerdoBand = 0;
          this.SelectFilter(0);
          this.sentenciaBand = false;
          window.$('.chips__choice .chip').removeClass('chip--active');
          window.$('#filter0').addClass("chip--active");
          break;
        case 4: 
          this.documento = "expediente";
          this.TypeNumber = 3;
          this.AcuerdoBand = 0;
          this.SelectFilter(0);
          this.sentenciaBand = true;
          window.$('.chips__choice .chip').removeClass('chip--active');
          window.$('#filter0').addClass("chip--active");
          break;
        default:
          this.documento = "acuerdo";
          this.TypeNumber =2;
          this.AcuerdoBand = 1;
          this.sentenciaBand = false;
          break;
      }
    },

    SelectFilter(n){
      const selection = "#filter"+n;
      window.$('.chips__choice .chip').removeClass('chip--active');
      window.$(selection).addClass("chip--active");
      this.selectedFilter = n;
      var time = new Date();
      var theyear = time.getFullYear();
      var themonth = ((time.getMonth() + 1) >= 10) ? (time.getMonth() + 1) : "0"+(time.getMonth() + 1);
      var thetoday = (time.getDate() >= 10) ? time.getDate() : ("0"+time.getDate());
      console.log("El Filtro: "+ this.selectedFilter);
      switch(this.selectedFilter){
        case 10:
          this.makeTextType();
          this.hintText ="Seleccionar un filtro hara tu busqueda mas rapida";
          this.filtro = 0;
          break;
        case 0:
          this.makeTextType();
          if(this.organo == "tev"){
            this.busquedaText = "TEV-"
            this.hintText = "EJEMPLO: TEV-PES-59/2021 o TEV-PES-59-2021";
          }else{
            this.busquedaText = "SX-";
            this.hintText = "EJEMPLO: SX-JRC-180/2021 o SX-JRC-180-2021";   
          }
          this.filtro = 1;
        break;
        case 1: 
          this.makeDateType();
          this.hintText ="Selecciona una fecha pulsando el icono de calendario"
          this.filtro = 2;
          break;  
        case 2: 
          this.makeDateType();
          
          this.hintText ="Los resultados corresponderán con la fecha del día de hoy " +thetoday+"-"+themonth+"-"+theyear;
          this.filtro = 2;
          this.busquedaText = theyear+"-"+themonth+"-"+thetoday;
          this.BuscarContenido(this.busquedaText);
          break;
        default:
          this.makeTextType();
          if(this.organo == "tev"){
            this.busquedaText = "TEV-"
            this.hintText = "EJEMPLO: TEV-PES-59/2021 o TEV-PES-59-2021";
          }else{
            this.busquedaText = "SX-";
            this.hintText = "EJEMPLO: SX-JRC-180/2021 o SX-JRC-180-2021";   
          }
          this.filtro = 1;
          break;
      
      }
    },

    makeDateType(){
      let myInput = document.getElementById("busquedaInputId");
      myInput.type = "date";
    },

    makeTextType(){
      let myInput = document.getElementById("busquedaInputId");
      myInput.type = "text";
    },


    BuscarContenido(n){
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date+' '+time;

      analytics.logEvent("Busqueda-ESearch",{
        event_title: ""+ this.estado  +" - "+ this.organo +" - "+ this.documento +" - "+ this.busquedaText,
        event_time: dateTime,
        event_label: dateTime,
        event_category: ""+ this.estado  +" - "+ this.organo +" - "+ this.documento +" - "+ this.busquedaText
      });
      this.busquedaText = this.busquedaText.toUpperCase();
      n = this.busquedaText.toUpperCase();
      console.log("Texto De Busqueda: "+ n);
      console.log("EL FILTO: "+this.filtro);
      console.log("ThisBusquedaText"+this.busquedaText);
      switch(this.filtro){
        case 1:
          this.BuscaPorExpediente(n);
        break;
        case 2: 
          this.BuscarPorFecha(n);
        break;
        default:
          this.BuscaPorExpediente(n);
        break;
      }
      
    },
    deleteSearchInfo(){
      this.resultadosBand = 0;
      /*document.getElementById("busquedaInputId").select();*/
    },

    AbrirArchivo(n){
      const urlDocumento = this.resultadosBusqueda[n].Doc_Url;
      window.open(urlDocumento);
    },

    BuscarPorFecha(TextoBusqueda){
      console.log("DATA "+ this.SelectedOrgano + " / ", + this.SelectedState + " EL TEXTO: "+ TextoBusqueda);

      if(this.SelectedState == 1 && this.SelectedOrgano == 1){
        console.log("LA INFO QUE LE DOY AL FIREBASE: ")
        console.log("La Fecha: "+TextoBusqueda);
        console.log("El Documento A Buscar: "+this.documento);
        this.resultadosBand = 0;
        const resultados = [];
          fr.collection("electoralSearch")
          .where("Fecha", "==", TextoBusqueda)
          .where("Documento", "==", this.documento)
          .get()
          .then( snapshot  =>  {
            snapshot.docs.forEach(resultado =>{
              let currentID = resultado.id
              let appObj = {...resultado.data(), ['id']: currentID }
              resultados.push(appObj)
            });
            this.resultadosBusqueda = resultados;
            console.log("Resultado De La Busqueda: ");
            console.log(this.resultadosBusqueda);
            this.resultadosCantidad = this.resultadosBusqueda.length;
            this.resultadosBand = 1;
          });
      } else {
        if(this.SelectedState == 1 && this.SelectedOrgano > 1){
          this.resultadosBand = 0;
          const resultados = [];
            fr.collection("electoralSearch")
            .where("Fecha", "==", TextoBusqueda)
            .where("Doc_Organo", "==", this.organo)
            .where("Documento", "==", this.documento)
            .get()
            .then( snapshot  =>  {
              snapshot.docs.forEach(resultado =>{
                let currentID = resultado.id
                let appObj = {...resultado.data(), ['id']: currentID }
                resultados.push(appObj)
              });
              this.resultadosBusqueda = resultados;
              console.log("Resultado De La Busqueda: ");
              console.log(this.resultadosBusqueda);
              this.resultadosCantidad = this.resultadosBusqueda.length;
              this.resultadosBand = 1;
            });
        } else {
          if(this.SelectedOrgano == 1 && this.SelectedState > 1){
            this.resultadosBand = 0;
            const resultados = [];
              fr.collection("electoralSearch")
              .where("Fecha", "==", TextoBusqueda)
              .where("Estado", "==" , this.estado)
              .where("Documento", "==", this.documento)
              .get()
              .then( snapshot  =>  {
                snapshot.docs.forEach(resultado =>{
                  let currentID = resultado.id
                  let appObj = {...resultado.data(), ['id']: currentID }
                  resultados.push(appObj)
                });
                this.resultadosBusqueda = resultados;
                console.log("Resultado De La Busqueda: ");
                console.log(this.resultadosBusqueda);
                this.resultadosCantidad = this.resultadosBusqueda.length;
                this.resultadosBand = 1;
              });
          } else {
            console.log("Default Entry - Entre");
            this.resultadosBand = 0;
            const resultados = [];
              fr.collection("electoralSearch")
              .where("Fecha", "==", TextoBusqueda)
              .where("Estado", "==" , this.estado)
              .where("Doc_Organo", "==", this.organo)
              .where("Documento", "==", this.documento)
              .get()
              .then( snapshot  =>  {
                snapshot.docs.forEach(resultado =>{
                  let currentID = resultado.id
                  let appObj = {...resultado.data(), ['id']: currentID }
                  resultados.push(appObj)
                });
                this.resultadosBusqueda = resultados;
                console.log("Resultado De La Busqueda: ");
                console.log(this.resultadosBusqueda);
                this.resultadosCantidad = this.resultadosBusqueda.length;
                this.resultadosBand = 1;
              });
          }
        }

      }    
    },

    SentenciaFiltro(){
      console.log("EL SENTENCIAS BAND: ",this.sentenciaBand);
      if(this.sentenciaBand){
        var newArray = this.resultadosBusqueda.reduce((newArray, resultadosBusqueda) => {
          if (resultadosBusqueda.Doc_Name.match(/SENTENCIA/g) || resultadosBusqueda.Doc_Name.match(/ACUERDO DE SALA/g) || resultadosBusqueda.Doc_Name.match(/RESOLUCION/g)) {
            newArray.push({
              'Doc_Name' : resultadosBusqueda.Doc_Name,
              'Doc_Url' : resultadosBusqueda.Doc_Url,
            });
          }
          return newArray;
        }, []);
        
        this.resultadosBusqueda = newArray;
        this.resultadosCantidad = this.resultadosBusqueda.length;
      }
      console.log("Sentencia Filtro: ",this.resultadosBusqueda);
    },

    async BuscaPorExpediente(n){
      if(this.SelectedState == 1 && this.SelectedOrgano == 1){
        this.resultadosBand = 0;
        const resultados = [];
           await fr.collection("electoralSearch")
          .where("Num_Expediente", "==", n)
          .where("Documento", "==", this.documento)
          .get()
          .then( snapshot  =>  {
            snapshot.docs.forEach(resultado =>{
              let currentID = resultado.id
              let appObj = {...resultado.data(), ['id']: currentID }
              resultados.push(appObj)
            });
            this.resultadosBusqueda = resultados;
            console.log("Resultado De La Busqueda: ");
            console.log(this.resultadosBusqueda);
            this.resultadosCantidad = this.resultadosBusqueda.length;
            this.resultadosBand = 1;
            this.SentenciaFiltro();
          });
      } else {
        if(this.SelectedState == 1 && this.SelectedOrgano > 1){
          this.resultadosBand = 0;
          const resultados = [];
            await fr.collection("electoralSearch")
            .where("Num_Expediente", "==", n)
            .where("Doc_Organo", "==", this.organo)
            .where("Documento", "==", this.documento)
            .get()
            .then( snapshot  =>  {
              snapshot.docs.forEach(resultado =>{
                let currentID = resultado.id
                let appObj = {...resultado.data(), ['id']: currentID }
                resultados.push(appObj)
              });
              this.resultadosBusqueda = resultados;
              console.log("Resultado De La Busqueda: ");
              console.log(this.resultadosBusqueda);
              this.resultadosCantidad = this.resultadosBusqueda.length;
              this.resultadosBand = 1;
              this.SentenciaFiltro();
            });
        } else {
          if(this.SelectedOrgano == 1 && this.SelectedState > 1){
            this.resultadosBand = 0;
            const resultados = [];
               await fr.collection("electoralSearch")
              .where("Num_Expediente", "==", n)
              .where("Estado", "==" , this.estado)
              .where("Documento", "==", this.documento)
              .get()
              .then( snapshot  =>  {
                snapshot.docs.forEach(resultado =>{
                  let currentID = resultado.id
                  let appObj = {...resultado.data(), ['id']: currentID }
                  resultados.push(appObj)
                });
                this.resultadosBusqueda = resultados;
                console.log("Resultado De La Busqueda: ");
                console.log(this.resultadosBusqueda);
                this.resultadosCantidad = this.resultadosBusqueda.length;
                this.resultadosBand = 1;
                this.SentenciaFiltro();
              });
          } else {
            this.resultadosBand = 0;
            const resultados = [];
               await fr.collection("electoralSearch")
              .where("Num_Expediente", "==", n)
              .where("Estado", "==" , this.estado)
              .where("Doc_Organo", "==", this.organo)
              .where("Documento", "==", this.documento)
              .get()
              .then( snapshot  =>  {
                snapshot.docs.forEach(resultado =>{
                  let currentID = resultado.id
                  let appObj = {...resultado.data(), ['id']: currentID }
                  resultados.push(appObj)
                });
                this.resultadosBusqueda = resultados;
                console.log("Resultado De La Busqueda: ");
                console.log(this.resultadosBusqueda);
                this.resultadosCantidad = this.resultadosBusqueda.length;
                this.resultadosBand = 1;
                this.SentenciaFiltro();
              });
          }
        }
      } 
      //END HERE
      


    },
  },
  
}
</script>


<style scoped>
  .date{
    color: rgb(27, 155, 10)!important;
  }

  .resultados-text{
    font-family: Poppins;
    font-size: 1.4em;
    font-weight: bold;

  }

  .resultados-box{
    margin-top: 100px;

  }

 .text-filtro{
   color: #ab4deb;
   font-family: Poppins;
   font-weight: 800;
   margin-top: auto;
   margin-bottom: 8px;
  
 }
 .date{
  height: 100px;
   color: white;
 }

.searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 10px;
    width: 50%;
    }

    .search_input{
    color: white;
    text-transform: uppercase;
    font-size:100% ;
    padding: 0 10px;
    border: 0;  
    outline: 0;
    background: none;
    width: 80%;
    height: 100%;
    caret-color:rgb(72, 175, 31);
    line-height: 40px;
    transition: width 0.4s linear;
    }
    .searchbar:hover > .search_icon{
    background: white;
    color: #ab4deb;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
      height: 45px;
      width: 65px;
      margin-left: 15px;

    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    text-decoration:none;
    }

    .promo-wrapper {
      cursor: pointer;
    }

    .homepage-3 .single-promo h3 {
      font-size: 28px;
    }
    .active{
      background: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      background-color: #e3d6ff;
      border-bottom: solid 5px #2f0c71;
      color: black;
      font-weight: bold;
    }

    .nav-link{
      font-size: 18px;
    }

    .encabezados{
      overflow: auto;
    }

    .chip {
    padding: 0 12px;
    background-color: rgba(175, 175, 175, 0.637);
    border-radius: 100px;
    display: inline-flex;
    margin: 4px;
    color: rgba(0, 0, 0, .87);
    align-items: center;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    }

    .chip .icon {
        font-size: 16px;
        color: rgba(0, 0, 0, .54);
        width: 20px;
        text-align: center;
    }

    .chip .icon--leadind {
        margin: 2px 4px 0 -4px;
    }

    .chip .icon--trailing {
        margin: 2px -4px 0 4px;
    }

    .chips__choice .chip.chip--active {
        color: white;
        background-color: #832b83;
    }

    .chips__filter .chip .chip--check {
        display: none;
    }

    .chips__filter .chip.chip--active .chip--check {
        display: none;
    }
</style>