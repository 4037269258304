<template>
  <div>
    <template>
      <div>
        <ScrollupSection />
        <div class="main overflow-hidden">
          <HeaderSection />
          <PerfilBreadcrumb :titulo="titulo" :descripcion="descripcion" />
          <section id="service" class="section service-area m-5">
            <div class="terminos-container text-justify">
              <p class="title-terminos my-3">
                <b> POR FAVOR LEA ESTOS TÉRMINOS DE USO DETENIDAMENTE ANTES DE UTILIZAR ESTE SITIO. </b>
              </p>
              <p class="sub-title-terminos mt-5"> <b> TÉRMINOS Y CONDICIONES DE USO </b>  </p>
              <p class="my-3">
                Al utilizar el sitio web electoraltech.com.mx (el "Sitio") o cualquier aplicación de ElectoralTech & Legal o complementos de aplicación 
                ("Aplicaciones"), usted acepta seguir y estar sujeto a estos términos de uso (los "Términos de uso”) y acepta cumplir con todas las leyes
                y regulaciones aplicables. En estos Términos de uso, las palabras "usted" y "su" se refieren a cada cliente, visitante del Sitio o usuario 
                de la Aplicación, "nosotros", "nos" y "nuestro" se refieren a ElectoralTech & Legal. y "Servicios" se refiere a todos los servicios 
                proporcionados por nosotros.Es su responsabilidad revisar estos Términos de uso periódicamente. Si en algún momento considera que estos 
                Términos de uso son inaceptables o si no está de acuerdo con estos Términos de uso, no utilice este Sitio ni ninguna de las Aplicaciones. 
                Podemos revisar estos Términos de uso en cualquier momento sin notificárselo. Si tiene alguna pregunta sobre estos Términos de uso, 
                comuníquese con nuestro Centro de atención al Cliente.
              </p>
              <p class="mb-3">
                <b>USTED ACEPTA QUE, AL UTILIZAR EL SITIO, CUALQUIER APLICACIÓN Y LOS SERVICIOS, TIENE AL MENOS 18 AÑOS DE EDAD Y PUEDE LEGALMENTE ENTRAR EN UN CONTRATO. </b>
              </p>
              <p class="mb-2">
                Estos Términos requieren el uso de arbitraje de forma individual para resolver disputas, en lugar de juicios con jurado o acciones colectivas, 
                y también limitan los recursos disponibles para usted en caso de una disputa. ElectoralTech & Legal proporciona un portal legal en línea para 
                brindar a los visitantes una comprensión general de la ley y brindar una solución de software automatizada a las personas que eligen preparar 
                sus propios documentos legales. El cliente no necesita descargar ni licenciar el software ElectoralTech & Legal. El Sitio incluye información 
                general sobre problemas legales que se encuentran comúnmente. Los Servicios de ElectoralTech & Legal también incluyen una revisión de sus 
                respuestas para verificar que estén completas, ortográficas y para que los nombres, direcciones y similares sean coherentes. En ningún 
                momento revisamos sus respuestas para determinar su suficiencia legal, sacamos conclusiones legales, brindamos asesoría legal, opiniones o 
                recomendaciones sobre sus derechos legales, recursos, defensas, opciones, selección de formas o estrategias.
              </p>
              <p class="mb-2">
                ElectoralTech & Legal se esfuerza por mantener sus documentos legales precisos y actualizados. Sin embargo, debido a que la ley cambia rápidamente, 
                ElectoralTech & Legal no puede garantizar que toda la información en el Sitio o las Aplicaciones esté completamente actualizada. La ley es diferente 
                de una jurisdicción a otra y puede estar sujeta a interpretación por diferentes tribunales. La ley es un asunto personal y ninguna información general 
                o herramienta legal como la que brinda ElectoralTech & Legal puede adaptarse a todas las circunstancias. Además, la información legal contenida en el 
                Sitio y las Aplicaciones no es un consejo legal y no se garantiza que sea correcta, completa o actualizada. Por lo tanto, si necesita asesoramiento
                legal para su problema específico, o si su problema específico es demasiado complejo para ser abordado por nuestras herramientas, debe consultar
                a un abogado con licencia en su área.  
              </p>
              <p class="mb-5">
                De vez en cuando, ElectoralTech & Legal puede realizar ciertos servicios de acceso de abogados y presentar a nuestros visitantes a los abogados a 
                través de varios métodos, que incluyen, entre otros, (i) planes legales, (ii) listas de directorios de abogados de terceros y (iii) alcance limitado 
                de terceros acuerdos. En ningún momento se fomenta o crea una relación abogado-cliente con ElectoralTech & Legal mediante la prestación de dichos 
                servicios.
              </p>

              <p class="my-2">
                <b> 1. Política de privacidad. </b> ElectoralTech & Legal respeta su privacidad y le permite controlar el tratamiento de su información personal.
                Puede encontrar una declaración completa de la Política de privacidad actual de ElectoralTech & Legal haciendo clic <a class="link-to-policy" href="$#"> aquí</a>. La Política de privacidad
                de ElectoralTech & Legal se incorpora expresamente a este Acuerdo por referencia.
              </p>
              <p class="mb-2">
                Cuando abre una cuenta para utilizar o acceder a determinadas partes del Sitio, las Aplicaciones o los Servicios, debe proporcionar la información 
                completa y precisa que se solicita en el formulario de registro. También se le pedirá que proporcione un nombre de usuario y una contraseña. Eres 
                totalmente responsable de mantener la confidencialidad de tu contraseña. No puede utilizar la cuenta, el nombre de usuario o la contraseña de un tercero
                en ningún momento. Usted acepta notificar a ElectoralTech & Legal inmediatamente de cualquier uso no autorizado de su cuenta, nombre de usuario o 
                contraseña. ElectoralTech & Legal no será responsable de ninguna pérdida en la que incurra como resultado del uso de su cuenta o contraseña por 
                parte de otra persona, ya sea con o sin su conocimiento. Usted puede ser responsable de las pérdidas incurridas por ElectoralTech & Legal, nuestras 
                afiliadas, funcionarios, directores, empleados, consultores, agentes y representantes debido a otra persona.
              </p>
              <p class="mb-5">
                En relación con el uso de ciertos productos o servicios de ElectoralTech & Legal, es posible que se le solicite que proporcione información personal 
                en un cuestionario, solicitud, formulario o documento o servicio similar. Esta información estará protegida de acuerdo con nuestra Política de privacidad. 
                Además, otorga a ElectoralTech & Legal una licencia mundial, libre de regalías, no exclusiva y totalmente sublicenciable para usar, distribuir, reproducir, 
                modificar, publicar y traducir esta información personal únicamente con el fin de permitirle el uso del servicio correspondiente. Puede revocar esta 
                licencia y rescindir los derechos de ElectoralTech & Legal en cualquier momento eliminando su información personal del servicio correspondiente.
              </p>

              <p class="mt-2 mb-5">
                <b>2. Propiedad.</b> Este Sitio y las Aplicaciones son propiedad y están operados por ElectoralTech & Legal S.A Todos los derechos, títulos e intereses 
                en los materiales proporcionados en este Sitio y las Aplicaciones, incluidos, entre otros, información, documentos, logotipos, gráficos, sonidos e imágenes
                (Los "Materiales") son propiedad de ElectoralTech & Legal o de nuestros respectivos autores, desarrolladores o proveedores externos ("Proveedores externos"). 
                Salvo que ElectoralTech & Legal indique expresamente lo contrario, ninguno de los Materiales puede ser copiado, reproducido, republicado, descargado, cargado, 
                publicado, mostrado, transmitido o distribuido de ninguna manera y nada en este Sitio o en ninguna Aplicación se interpretará como una concesión de licencia.
                bajo cualquiera de los derechos de propiedad intelectual de ElectoralTech & Legal, ya sea por impedimento, implicación o de otro modo. Consulte la 
                "Información de contacto legal" a continuación si tiene alguna pregunta sobre cómo obtener dichas licencias. ElectoralTech & Legal no vende, licencia, 
                arrienda ni proporciona ningún Material que no sean los específicamente identificados como proporcionados por ElectoralTech & Legal. ElectoralTech & Legal 
                se reserva todos los derechos no otorgados expresamente en este documento.
              </p>
              <p class="mt-2 mb-5">
                <b>3. Permiso limitado para descargar.</b>
                ElectoralTech & Legal por la presente le otorga permiso para descargar, ver, copiar e imprimir los Materiales en cualquier computadora individual e independiente 
                (únicamente para su uso personal, informativo y no comercial siempre que (i) cuando se proporcionen, los avisos de derechos de autor y marcas comerciales que aparecen 
                en cualquier Material no se modifiquen ni eliminen, (ii) los Materiales no se utilicen en ningún otro sitio web o en una red entorno informático y (iii) los Materiales 
                no se modifican de ninguna manera, excepto para la edición autorizada de formularios descargables para uso personal. Este permiso termina automáticamente sin previo aviso
                 si incumple cualquiera de los términos o condiciones de estos Términos de uso. En tal terminación, acepta destruir inmediatamente cualquier Material descargado o impreso. 
                 Cualquier uso no autorizado de cualquier Material contenido en este Sitio o Aplicaciones puede violar las leyes de derechos de autor, leyes de marcas registradas, 
                 leyes de privacidad y regulaciones y estatutos de publicidad y comunicaciones.
              </p>

              <p class="mt-2 mb-5">
                <b>4. Enlaces a sitios de terceros. </b>
                Este Sitio y las Aplicaciones pueden contener enlaces a sitios web controlados por terceros distintos a ElectoralTech & Legal (cada uno de ellos un "Sitio de terceros"). 
                ElectoralTech & Legal trabaja con varios socios y afiliados cuyos sitios están vinculados con ElectoralTech & Legal. ElectoralTech & Legal también puede proporcionar 
                enlaces a otras citas o recursos con los que no está afiliado. ElectoralTech & Legal no es responsable ni respalda ni acepta responsabilidad alguna por la disponibilidad, 
                los contenidos, los productos, los servicios o el uso de cualquier Sitio de terceros, cualquier sitio web al que se acceda desde un Sitio de terceros o cualquier 
                cambio o actualización de dichos sitios. ElectoralTech & Legal no ofrece garantías sobre el contenido o la calidad de los productos o servicios proporcionados por dichos sitios. 
                ElectoralTech & Legal no es responsable de la transmisión por Internet o cualquier otra forma de transmisión recibida de cualquier sitio de terceros. 
                ElectoralTech & Legal le proporciona estos enlaces solo para su conveniencia, y la inclusión de cualquier enlace no implica el respaldo de ElectoralTech & Legal 
                del sitio de terceros, ni implica que ElectoralTech & Legal patrocina, está afiliado o asociado con, garantiza o está legalmente autorizado. utilizar cualquier 
                nombre comercial, marca registrada, logotipo, sello legal u oficial, o símbolo con derechos de autor que pueda estar reflejado en los enlaces. Usted reconoce que 
                asume todos los riesgos asociados con el acceso y el uso del contenido proporcionado en un Sitio de terceros y acepta que ElectoralTech & Legal no es responsable de 
                ninguna pérdida o daño de ningún tipo en el que pueda incurrir al tratar con un tercero. Debe comunicarse con el administrador del Sitio de terceros correspondiente 
                si tiene alguna inquietud con respecto a dichos enlaces o el contenido ubicado en dicho Sitio de terceros.
              </p>

              <p class="mt-2 mb-5">
                <b>5. Uso de formularios legales de ElectoralTech & Legal.</b>
                En nuestro Sitio, a través de nuestras Aplicaciones y a través de ciertos socios, ofrecemos formularios de autoayuda para "llenar los espacios en blanco". 
                Si compra un formulario de uno de nuestros socios, será dirigido al sitio web de ese socio y prevalecerán sus términos de uso. Si compra o descarga un 
                formulario en nuestro Sitio o Aplicación, controlan los términos y condiciones de estos Términos de uso. Usted comprende que su compra, descarga y / o 
                uso de un documento de formulario no es un consejo legal ni la práctica de la ley, y que cada formulario y cualquier instrucción u orientación aplicable 
                no está personalizado para sus necesidades particulares.
              </p>

              <p class="sub-title-terminos mt-5 mb-3" > <b> LICENCIA DE USO </b> </p>
              <p class="mb-3">
                ElectoralTech & Legal le otorga una licencia limitada, personal, no exclusiva e intransferible para utilizar nuestros formularios (los "Formularios") 
                para su uso comercial interno, personal, o si es abogado o profesional, para su cliente. Salvo que se disponga lo contrario, usted reconoce y acepta 
                que no tiene derecho a modificar, editar, copiar, reproducir, crear trabajos derivados, realizar ingeniería inversa, alterar, mejorar o de alguna 
                manera explotar cualquiera de los Formularios de ninguna manera, excepto por modificaciones. al completar los Formularios para su uso autorizado. 
                No eliminará ningún aviso de derechos de autor de ningún formulario.
              </p>

              <p class="mb-5"> <b> Prohibida la reventa de formularios. </b>
              Al ordenar o descargar Formularios, usted acepta que los Formularios que compra o descarga solo pueden ser utilizados por usted para su uso 
              personal o comercial o que usted los use en relación con su cliente y no se pueden vender o redistribuir sin el consentimiento expreso por 
              escrito de ElectoralTech & Legal
              </p>

              <p class="mt-2 mb-5">
                <b>6. Resolución de controversias mediante arbitraje vinculante. </b> <br>
                <b> Por favor lea esto con atención. Afecta sus derechos.</b> 
                La mayoría de las inquietudes de los clientes se pueden resolver rápidamente y a satisfacción del cliente llamando a nuestro Centro de atención al 
                cliente al 228 30 78010. En el improbable caso de que el Centro de atención al cliente de ElectoralTech & Legal no pueda resolver su queja a su 
                satisfacción (o si ElectoralTech & Legal no ha podido resolver una disputa que tiene con usted después de intentar hacerlo de manera informal), 
                cada uno de nosotros acepta resolver esas disputas. mediante arbitraje vinculante o en un tribunal de reclamos menores en lugar de en un tribunal 
                de jurisdicción general.  El arbitraje es menos formal que una demanda judicial. El arbitraje utiliza un árbitro neutral en lugar de un juez o 
                jurado, permite un descubrimiento más limitado que un tribunal y está sujeto a una revisión muy limitada por parte de los tribunales. Cualquier 
                arbitraje bajo estos Términos se llevará a cabo de forma individual; No se permiten los arbitrajes colectivos ni las acciones colectivas.
                <span style="color: brown;"> Puede hablar con un abogado independiente antes de usar este Sitio o completar cualquier compra.</span>
                 <br>
                 <br>
                 Acuerdo de arbitraje: 
                 ElectoralTech & Legal y usted acuerdan arbitrar todas las disputas y reclamos entre nosotros ante un solo árbitro. Los tipos de disputas y reclamos
                 que aceptamos arbitrar están destinados a ser interpretados de manera amplia. Se aplica, sin limitación, a:
                 <br>
                 <ul class="mt-2 mx-5">
                  <li><b>Reclamaciones</b> que surjan de o se relacionen con cualquier aspecto de la relación entre nosotros, ya sea por contrato, agravio, estatuto, fraude, 
                    tergiversación o cualquier otra teoría legal;</li>
                  <li><b>Reclamaciones</b> que surgieron antes de estos o de cualquier Término anterior (incluidas, entre otras, reclamaciones relacionadas con la publicidad);</li>
                  <li><b>Reclamaciones</b> que actualmente son objeto de un supuesto litigio colectivo en el que usted no es miembro de una clase certificada; y</li>
                  <li><b>Reclamaciones</b> que puedan surgir después de la terminación de estos Términos.</li>
                </ul>
                <br>
                Para los propósitos de este Acuerdo de Arbitraje, las referencias a " ElectoralTech & Legal ", "usted" y "nosotros" incluyen nuestras respectivas subsidiarias, 
                afiliadas, agentes, empleados, empleadores, socios comerciales, accionistas, predecesores en interés, sucesores y cesionarios, como, así como todos los usuarios 
                o beneficiarios autorizados o no autorizados de servicios o productos bajo estos Términos o cualquier acuerdo previo entre nosotros. Los beneficiarios incluyen, 
                pero no se limitan a, aquellos nombrados en un documento de planificación patrimonial. 
              </p>
              <p class="mt-2 mb-5">
                <b>7. Condiciones adicionales. </b>
                Algunos Servicios de ElectoralTech & Legal pueden estar sujetos a pautas, reglas o términos de servicio adicionales publicados ("Términos adicionales") y 
                su uso de dichos Servicios estará condicionado a su aceptación de los Términos adicionales. Si existe algún conflicto entre estos Términos de uso y los 
                Términos adicionales, los Términos adicionales controlarán ese Servicio, a menos que los Términos adicionales establezcan expresamente que estos Términos 
                de uso prevalecerán.
              </p>
              <p class="mt-2">
                <b>8. Revisiones, comentarios, comunicaciones y otro contenido. </b>
                En varios lugares del Sitio o a través de las Aplicaciones, ElectoralTech & Legal puede permitir que los visitantes publiquen calificaciones, reseñas, 
                comentarios, preguntas, respuestas y otro contenido (el "Contenido del usuario"). Las contribuciones, el acceso y el uso del Contenido del usuario 
                están sujetos a este párrafo y a los demás términos y condiciones de estos Términos de uso.
              </p>
              <br>
              <p>
                <b>Derechos y responsabilidades de ElectoralTech & Legal. </b> ElectoralTech & Legal no es el editor ni el autor del Contenido del usuario. 
                ElectoralTech & Legal no asume ninguna responsabilidad por cualquier contenido publicado por usted o un tercero.
                Aunque no podemos ofrecer una garantía absoluta de seguridad del sistema, ElectoralTech & Legal toma medidas razonables para mantener la seguridad. 
                Si tiene motivos para creer que se ha violado la seguridad del sistema, comuníquese con nosotros por correo electrónico para obtener ayuda.
              </p>
              <br>
              <p>
                Si el personal técnico de ElectoralTech & Legal encuentra que los archivos o procesos que pertenecen a un miembro representan una amenaza 
                para el funcionamiento técnico adecuado del sistema o para la seguridad de otros miembros, ElectoralTech & Legal se reserva el derecho de 
                eliminar esos archivos o detener esos procesos. Si el personal técnico de ElectoralTech & Legal sospecha que alguien que no está autorizado 
                por el usuario apropiado está utilizando un nombre de usuario, ElectoralTech & Legal puede deshabilitar temporalmente el acceso de ese usuario 
                para preservar la seguridad del sistema. En todos estos casos, ElectoralTech & Legal se comunicará con el miembro tan pronto como sea posible.
              </p>
              <br>
              <p>
                ElectoralTech & Legal tiene el derecho (pero no la obligación), a nuestro exclusivo y absoluto criterio, de editar, redactar, eliminar, 
                volver a categorizar a una ubicación más apropiada o cambiar cualquier Contenido de usuario.
              </p>
              <br>
              <p> <b> Derechos y responsabilidades de los usuarios de ElectoralTech & Legal u otros carteles de contenido de usuario.</b> 
              Usted es legal y éticamente responsable de cualquier Contenido de usuario (escritos, archivos, imágenes o cualquier otro trabajo) que publique 
              o transmita utilizando cualquier servicio de ElectoralTech & Legal que permita la interacción o difusión de información. Al publicar Contenido 
              de usuario, acepta que no enviará ningún contenido:
                <ul class="mt-2 mx-5">
                  <li> <b>Que</b> usted sepa que es falso, inexacto o engañoso;</li>
                  <li> <b>Que</b> infrinja los derechos de autor, patentes, marcas comerciales, secretos comerciales u otros derechos de propiedad o derechos de 
                    publicidad o privacidad de cualquier persona. Consulte Cumplimiento de las leyes de propiedad intelectual a continuación;</li>
                  <li> <b>Que</b> viole cualquier ley, estatuto, ordenanza o reglamento (incluidos, entre otros, los que rigen el control de exportaciones, 
                    la protección del consumidor, la competencia desleal, la lucha contra la discriminación o la publicidad engañosa). Consulte 
                    Cumplimiento de las restricciones de exportación a continuación;</li>
                  <li> <b>Que</b> sea, o pueda razonablemente ser considerado, difamatorio, calumnioso, odioso, racial o religioso o con prejuicios u ofensivo, 
                    ilegalmente amenazador o ilegalmente acosador, o que defienda o aliente una conducta ilegal dañina para cualquier individuo, sociedad
                     o corporación. Consulte Contenido inapropiado a continuación;</li>
                  <li> <b>Que</b> incluye publicidad, correo no deseado o contenido por el que un tercero le compensó o le otorgó alguna consideración;</li>
                  <li> <b>Que</b> incluye información que hace referencia a otros sitios web, direcciones, direcciones de correo electrónico, números de teléfono u otra información de contacto;</li>
                  <li> <b>Que</b> contenga virus informáticos, gusanos u otros programas o archivos informáticos potencialmente dañinos;</li>
                  <li> <b>Que</b> de otra manera viole estos Términos de uso</li>
                </ul>
              </p>
              <br>
              <p>
                Usted conserva los derechos de autor sobre todas las obras que crea y publica como Contenido de usuario, a menos que elija específicamente renunciar a ellas. 
                Al publicar un trabajo como Contenido de usuario, usted autoriza a otros miembros que tienen acceso a ese servicio a hacer un uso personal y habitual del trabajo, 
                incluida la creación de enlaces o volver a publicarlo, pero no a reproducirlo o difundirlo de otra manera, a menos que otorgue permiso para dicha difusión 
              </p>
              <br>
              <p>
                Usted otorga a ElectoralTech & Legal un derecho y una licencia perpetuos, irrevocables, libres de regalías y transferibles para usar, copiar, modificar, 
                eliminar en su totalidad, adaptar, publicar, traducir, crear trabajos derivados de, vender, distribuir y / o incorporar dicho contenido en cualquier forma, 
                medio o tecnología en todo el mundo sin compensación para usted. Tiene derecho a eliminar cualquiera de sus obras del Contenido de usuario en cualquier momento.
              </p>
              <br>
              <p>
                No es necesario que proporcione su nombre real al registrarse como usuario de ElectoralTech & Legal. ElectoralTech & Legal permite cuentas anónimas o seudónimas. 
                Cualquier usuario puede solicitar que se oculte la dirección de correo electrónico de dicho miembro para brindar privacidad adicional.
              </p>
              <br>
              <p>
                Las calificaciones y revisiones generalmente se publicarán entre dos a veinte días hábiles. Al enviar su dirección de correo electrónico en relación con su 
                calificación y revisión, acepta que ElectoralTech & Legal puede usar su dirección de correo electrónico para comunicarse con usted sobre el estado de su revisión 
                y otros fines administrativos.
              </p>

              <p class="mt-5"> <b>9. sin garantía.</b>
              el sitio, las aplicaciones y todos los materiales, documentos o formularios proporcionados en o mediante su uso del sitio o aplicaciones se proporcionan "tal cual" y 
              "según disponibilidad". en la medida máxima permitida por la ley, ElectoralTech & Legal renuncia expresamente a todas las garantías de cualquier tipo, ya 
              sean expresas o implícitas, incluyendo, pero no limitado a, las garantías implícitas de comerciabilidad, adecuación para un fin determinado infracción.
              </p>
              <br>
              <p>
                ElectoralTech & Legal no garantiza que: (a) el sitio, las aplicaciones o los materiales cumplan con sus requisitos; (b) el sitio, las aplicaciones o los materiales 
                estarán disponibles de forma ininterrumpida, oportuna, segura o sin errores; (c) los resultados que se puedan obtener del uso del sitio, aplicaciones o cualquier material 
                ofrecido a través del sitio o aplicaciones, serán exactos o confiables; o (d) la calidad de cualquier producto, servicio, información u otro material comprado u obtenido 
                por usted a través del sitio, aplicaciones o en función de los materiales cumplirá con sus expectativas.
              </p>
              <br>
              <p>
                Obtener cualquier material a través del uso del sitio o de las aplicaciones se hace a su propia discreción y bajo su propio riesgo. ElectoralTech & Legal no tendrá 
                responsabilidad por cualquier daño a su sistema de computadora o pérdida de datos que resulten de la descarga de cualquier contenido, material, información o software
              </p>
              <p class="mt-5"> <b>10. limitación de responsabilidad e indemnización. </b>
                excepto lo prohibido por la ley, usted mantendrá a ElectoralTech & Legal y a sus funcionarios, directores, empleados y agentes inofensivos por cualquier daño indirecto, 
                punitivo, especial, incidental o consecuente, sin embargo, se produce (incluyendo gastos de abogados y exenciones de honorarios litigio y arbitraje, o en juicio o en 
                apelación, si se instituye alguna, sea instituida o no litigio o arbitraje, ya sea en una acción de contrato, negligencia u otra acción torcida, o que surja fuera de 
                o en relación con este acuerdo, sin limitación, cualquier reclamo por lesiones personales o daños a la propiedad, que surjan de este acuerdo y cualquier violación por 
                su parte de cualquier ley, estatuto, reglamento o reglamento federal, estatal o local, incluso si ElectoralTech & Legal ha sido previamente avisado de dicho daño . 
                excepto lo prohibido por ley, si se encuentra responsabilidad por parte de ElectoralTech & Legal, se limitará a la cantidad pagada por los productos y / o servicios, 
                y bajo ninguna circunstancia habrá daños consecuentes o punitivos. 
              </p>
              <p class="mt-5"> <b>11. Envíos no solicitados.</b>
                Excepto cuando sea necesario en relación con su uso de los Servicios de ElectoralTech & Legal, ElectoralTech & Legal no desea que nos envíe información confidencial 
                o de propiedad a través de este Sitio o cualquier Aplicación. Todos los comentarios, retroalimentación, información o material enviado a ElectoralTech & Legal a 
                través o en asociación con este Sitio se considerarán no confidenciales y propiedad de ElectoralTech & Legal. Al proporcionar dichos envíos a ElectoralTech & Legal, 
                usted cede a ElectoralTech & Legal, sin cargo, todos los derechos, títulos e intereses en todo el mundo sobre los envíos y cualquier derecho de propiedad intelectual 
                asociado con ellos. ElectoralTech & Legal será libre de usar y / o difundir dichos envíos sin restricciones para cualquier propósito. Usted reconoce que es responsable 
                de los envíos que proporciona, incluida su legalidad, confiabilidad e idoneidad. 
              </p>
              <p class="mt-5"> <b>12. Cumplimiento de las leyes de propiedad intelectual.</b>
                Al acceder a ElectoralTech & Legal o utilizar el Servicio de preparación de documentos legales de ElectoralTech & Legal, usted acepta obedecer la ley y acepta 
                respetar los derechos de propiedad intelectual de otros. Su uso del Servicio y del Sitio se rige en todo momento y está sujeto a las leyes sobre derechos de autor, 
                marcas comerciales y otra propiedad intelectual. Usted acepta no cargar, descargar, mostrar, ejecutar, transmitir o distribuir de otro modo cualquier información 
                o contenido en violación de los derechos de autor, marcas comerciales u otros derechos de propiedad intelectual o de propiedad de terceros. Acepta acatar las leyes 
                relativas a la propiedad de los derechos de autor y el uso de la propiedad intelectual. 
              </p>
              <br>
              <p>
                ElectoralTech & Legal ha adoptado una política que prevé la eliminación inmediata de cualquier contenido, artículo o material que haya infringido los derechos de 
                ElectoralTech & Legal o de un tercero o que viole los derechos de propiedad intelectual en general. La política de ElectoralTech & Legal es eliminar dicho contenido 
                o materiales infractores e investigar dichas acusaciones de inmediato.
              </p>
              <p class="mt-5"> <b>Infracción de copyright:</b>
              <br>
              <br>
                Aviso. ElectoralTech & Legal ha implementado ciertos procedimientos obligatorios por ley con respecto a las acusaciones de infracción de derechos de autor que 
                ocurren en el Sitio o con el Servicio. La Compañía ha adoptado una política que prevé la suspensión y / o terminación inmediata de cualquier usuario del Sitio 
                o Servicio que se descubra que ha infringido los derechos de la Compañía o de un tercero, o que ha violado cualquier ley o reglamento intelectual. La política  
                de la Compañía es actuar rápidamente al recibir la notificación adecuada de una supuesta infracción de derechos de autor para eliminar o deshabilitar el acceso 
                al contenido presuntamente infractor. Si tiene pruebas, sabe o cree de buena fe que sus derechos o los derechos de un tercero han sido violados y desea que la  
                Compañía elimine, edite o inhabilite el material en cuestión, debe proporcionar a la Compañía la siguiente información por escrito: (1) Una firma física o  
                electrónica de una persona autorizada para actuar en nombre del propietario de un derecho exclusivo que supuestamente se infringe; (2) Identificación del  
                trabajo protegido por derechos de autor que se alega que se ha infringido o, si una sola notificación cubre varios trabajos protegidos por derechos de autor  
                en un solo sitio en línea, una lista representativa de dichos trabajos en ese sitio; (3) Identificación del material que se alega que infringe o que es objeto  
                de una actividad infractora y que debe eliminarse o cuyo acceso debe deshabilitarse e información razonablemente suficiente para permitir que el proveedor de  
                servicios ubique el material; (4) Información razonablemente suficiente para permitir que la Compañía se comunique con usted, como una dirección, número de  
                teléfono y, si está disponible, dirección de correo electrónico; (5) Una declaración de que usted cree de buena fe que el uso del material de la manera denunciada  
                no está autorizado por el propietario de los derechos de autor, su agente o la ley; y (6) Una declaración de que la información en la notificación es precisa y,  
                bajo pena de perjurio, que usted está autorizado a actuar en nombre del propietario de un derecho exclusivo que supuestamente ha sido infringido.
                La información escrita anterior debe enviarse a <b>contacto@electoraltech.com</b>
              </p>
              <p class="mt-5"> <b>Contra notificación</b>
              <br>
              <br>
                Si cree que su Contenido que se eliminó (o al que se deshabilitó el acceso) no está infringiendo, o que tiene la autorización del propietario de los derechos 
                de autor, el agente del propietario de los derechos de autor, o de conformidad con la ley, para publicar y usar el material en su Contenido, puede enviar una 
                contra notificación por escrito que contenga la siguiente información al Agente de derechos de autor: (1) Su firma física o electrónica; (2) Identificación 
                del Contenido que se ha eliminado o al que se ha deshabilitado el acceso y la ubicación en la que apareció el Contenido antes de ser eliminado o deshabilitado; 
                (3) Una declaración de que cree de buena fe que el Contenido se eliminó o desactivó como resultado de un error o una identificación errónea del Contenido; y 
                (4) Su nombre, dirección, número de teléfono y dirección de correo electrónico, una declaración de que acepta la jurisdicción del tribunal federal en Austin, 
                Texas, y una declaración de que aceptará la notificación del proceso de la persona que proporcionó la notificación de la presunta infracción. Si el Agente de
                derechos de autor recibe una contra notificación, la Compañía puede enviar una copia de la contra notificación a la parte reclamante original informándole a 
                esa persona que puede reemplazar el Contenido eliminado o dejar de deshabilitarlo en 10 días hábiles. A menos que el propietario de los derechos de autor 
                presente una acción solicitando una orden judicial contra el proveedor de Contenido, miembro o usuario, el Contenido eliminado puede ser reemplazado, o el 
                acceso a él restaurado, en 10 a 14 días hábiles o más después de recibir la contra notificación, en a exclusiva discreción de la Compañía. y una declaración 
                de que aceptará la notificación del proceso de la persona que proporcionó la notificación de la presunta infracción. Si el Agente de derechos de autor recibe 
                una contra notificación, la Compañía puede enviar una copia de la contra notificación a la parte reclamante original informándole a esa persona que puede 
                reemplazar el Contenido eliminado o dejar de deshabilitarlo en 10 días hábiles. A menos que el propietario de los derechos de autor presente una acción 
                solicitando una orden judicial contra el proveedor de Contenido, miembro o usuario, el Contenido eliminado puede ser reemplazado, o el acceso a él restaurado, 
                en 10 a 14 días hábiles o más después de recibir la contra notificación, en a exclusiva discreción de la Compañía. y una declaración de que aceptará la 
                notificación del proceso de la persona que proporcionó la notificación de la presunta infracción. Si el Agente de derechos de autor recibe una contra notificación,
                la Compañía puede enviar una copia de la contra notificación a la parte reclamante original informándole a esa persona que puede reemplazar el Contenido eliminado 
                o dejar de deshabilitarlo en 10 días hábiles. A menos que el propietario de los derechos de autor presente una acción solicitando una orden judicial contra el
                proveedor de Contenido, miembro o usuario, el Contenido eliminado puede ser reemplazado, o el acceso a él restaurado, en 10 a 14 días hábiles o más después de 
                recibir la contra notificación, en a exclusiva discreción de la Compañía. la Compañía puede enviar una copia de la contra notificación a la parte reclamante 
                original informándole a esa persona que puede reemplazar el Contenido eliminado o dejar de deshabilitarlo en 10 días hábiles. A menos que el propietario de 
                los derechos de autor presente una acción solicitando una orden judicial contra el proveedor de Contenido, miembro o usuario, el Contenido eliminado puede 
                ser reemplazado, o el acceso a él restaurado, en 10 a 14 días hábiles o más después de recibir la contra notificación, en a exclusiva discreción de la Compañía. 
                la Compañía puede enviar una copia de la contra notificación a la parte reclamante original informándole a esa persona que puede reemplazar el Contenido 
                eliminado o dejar de deshabilitarlo en 10 días hábiles. A menos que el propietario de los derechos de autor presente una acción solicitando una orden judicial 
                contra el proveedor de Contenido, miembro o usuario, el Contenido eliminado puede ser reemplazado, o el acceso a él restaurado, en 10 a 14 días hábiles o más
                después de recibir la contra notificación, en a exclusiva discreción de la Compañía.
              </p>

              <p class="mt-5"> <b>13. Contenido inapropiado.</b>
                Al acceder al Sitio, a cualquier Aplicación o al utilizar los Servicios de ElectoralTech & Legal, acepta no cargar, descargar, mostrar, ejecutar, transmitir 
                ni distribuir ningún contenido que: (i) sea calumnioso, difamatorio, obsceno, pornográfico, abusivo o amenazante; (b) defienda o fomente conductas que podrían 
                constituir un delito, dar lugar a responsabilidad civil o violar cualquier ley o reglamento local, estatal, nacional o extranjero aplicable; o (c) anuncia o 
                solicita fondos de otra manera o es una solicitud de bienes o servicios. ElectoralTech & Legal se reserva el derecho de cancelar o eliminar dicho material de
                 sus servidores. ElectoralTech & Legal cooperará plenamente con cualquier funcionario o agencia del orden público en la investigación de cualquier violación 
                 de estos Términos de uso o de cualquier ley aplicable.
              </p>

              <p class="mt-5"> <b>14. Cumplimiento de las restricciones a la exportación. </b>
                No puede acceder, descargar, usar o exportar el Sitio, las Aplicaciones o los Materiales en violación de las leyes o regulaciones de exportación o en 
                violación de cualquier otra ley o regulación aplicable. 
              </p>

              <p class="mt-5"> <b>15. Uso personal.</b>
                El sitio está disponible para su uso personal en su propio nombre.
              </p>

              <p class="mt-5"> <b>16. Niños. </b>
                Los menores de edad no son elegibles para usar el Sitio o las Aplicaciones y les pedimos que no nos envíen ninguna información personal.
              </p>

              <p class="mt-5"> <b>17. Clientes que no hablan español.  </b>
                Ciertos materiales en el sitio ElectoralTech & Legal, incluidos, entre otros, cuestionarios, documentos, instrucciones y presentaciones, 
                solo están disponibles en inglés. Las traducciones de estos Términos que no están en inglés, así como otros términos, condiciones y políticas, 
                se proporcionan solo para su comodidad. En caso de cualquier ambigüedad o conflicto entre las traducciones, la versión en español tiene autoridad y controla.
              </p>

              <p class="mt-5"> <b>18. Clientes que necesitan asistencia adicional. </b>
                ElectoralTech & Legal tiene como objetivo proporcionar acceso completo a su sitio web y ofertas de productos independientemente de la discapacidad. Si 
                no puede leer alguna parte del sitio web de ElectoralTech & Legal, o si tiene dificultades para usar el sitio web de ElectoralTech & Legal, llame al 228 30 78010
                y nuestro equipo de atención al cliente lo ayudará.
              </p>

              <p class="mt-5"> <b>19. Derechos de autor.</b>
                ElectoralTech & Legal tiene como objetivo proporcionar acceso completo a su sitio web y ofertas de productos independientemente de la discapacidad. Si 
                no puede leer alguna parte del sitio web de ElectoralTech & Legal, o si tiene dificultades para usar el sitio web de ElectoralTech & Legal, llame al 228 30 78010
                y nuestro equipo de atención al cliente lo ayudará.
              </p>

              <p class="mt-5"> <b>20. Marcas registradas. ElectoralTech & Legal.</b>
                El logotipo, todas las imágenes y texto, y todos los encabezados de página, gráficos personalizados e íconos de botones son marcas de servicio, marcas comerciales
                y / o imagen comercial de ElectoralTech & Legal. Todas las demás marcas comerciales, nombres de productos y nombres de empresas o logotipos citados en este 
                documento son propiedad de sus respectivos propietarios.
              </p>

              <p class="mt-5"> <b>21. Servicios de acceso de abogados.</b>
                Uso del término "experiencia”. El término "experiencia" o "experimentado", como se usa en el Sitio, las Aplicaciones y en otras comunicaciones en referencia a los 
                abogados de terceros que participan en los planes legales de ElectoralTech & Legal u otros servicios de acceso de abogados, significa que el socio principal de
                manejo del plan legal de cada bufete de abogados cumple con lo siguiente: (a) posee un mínimo de cinco años de experiencia en la práctica de leyes, (b) mantiene 
                pólizas de seguro contra errores y omisiones consistentes con los estándares de la industria, (c) está al día con el colegio de abogados del estado en cada 
                jurisdicción en la que el abogado tiene licencia para ejercer, (d) no tiene una demanda pendiente por negligencia, a la fecha de unirse a uno de los planes 
                legales de ElectoralTech & Legal, y (e) no tiene un registro público de disciplina por parte de un colegio de abogados estatal en los últimos cinco años. 
              </p>

              <p class="mt-5"> <b>22. Uso de testimonios y apoyos de los medios.</b>
                Los anfitriones de los medios en el Sitio respaldan a ElectoralTech & Legal como portavoces pagados en nuestras campañas publicitarias.
              </p>

              <p class="mt-5"> <b>23. Derecho a negarse.</b>
                Usted reconoce que ElectoralTech & Legal se reserva el derecho de rechazar el servicio a cualquier persona y cancelar el acceso del usuario en cualquier momento.
              </p>

              <p class="mt-5"> <b>24. Reconocimiento.</b>
                al utilizar los servicios de ElectoralTech & Legal o acceder al sitio o aplicaciones de ElectoralTech & Legal, usted reconoce que ha leído estos términos de uso 
                y acepta estar obligado por ellos.
              </p>





              

              

              
              




          
    




              


          </div>
          </section>
        <div>
          
        </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import PerfilBreadcrumb from "../components/Breadcrumb/PerfilBreadcrumb";


export default {
  name: "Terminos",
  components: {
    ScrollupSection,
    HeaderSection,
    PerfilBreadcrumb
  },
  data () {
    return {
      titulo: 'Terminos  y condiciones de uso',
      descripcion: ''
    }
  }
}
</script>

<style scoped>
p{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

.terminos-container{
  margin-left: 10%;
  margin-right: 10%;
}

.title-terminos{
  font-size: 2em;
}

.sub-title-terminos{
  font-size: 20px;
}

.link-to-policy{
  color: blue;
  text-decoration: underline;
}

</style>